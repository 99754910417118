// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~bootstrap-select/sass/bootstrap-select';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }
      }
    }
  }
}

// Custom
//@import "custom";
